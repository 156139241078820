import "./CourseAbout.css";

function CourseAbout({
  aboutUrl,
  aboutTitle,
  aboutTextFirst,
  isManSide,
  willLernArr,
  price
}) {
  return (
    <section className="course__about">
      <div className="course__about-texts">
        <h2
          className={`course__about-title text-title-one ${
            isManSide ? "" : "w"
          }`}
        >
          {aboutTitle}
        </h2>
        <h2
          className={`course__about-price text-title-two ${
            isManSide ? "" : "w"
          }`}
        >
         Вартість: {price}
        </h2>        <p className="course__about-description-one body-text">
          {aboutTextFirst}
        </p>
        <h3 className="will__lern-title text-title-two">
          На курсі ти дізнаєшся:
        </h3>
        <div className="will__lern-wrapper">
          {willLernArr.map((lern, idx) => {
            return (
              <p key={idx} className="will__lern-text body-text">
                {lern}
              </p>
            );
          })}
        </div>
      </div>
      <img src={aboutUrl} alt="фото курсу" className="course__about-image" />
    </section>
  );
}

export default CourseAbout;
